<template>
	<CustomModal
		:title="$t('Logout.Title')"
		:submit-text="$t('Logout.Submit')"
		:cancel-text="$t('Logout.Cancel')"
		@submit-modal="logout"
		@cancel-modal="cancel"
	>
		<template #content>
			{{ $t("Logout.Message") }}
		</template>
	</CustomModal>
</template>

<script>
import { LOGOUT } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("authentication")

export default {
	name: "Logout",

	methods: {
		...mapActions({ LOGOUT }),

		logout: async function () {
			await this.LOGOUT()
			this.cancel()
		},

		cancel: function () {
			this.$emit("hide")
		},
	},
}
</script>
